import dialogPolyfill from "dialog-polyfill";

/**
 * enables modern dialog support in older browsers, including IE9+
 */
function registerDialog() {
  document.querySelectorAll("dialog").forEach((dialog) => {
    dialogPolyfill.registerDialog(dialog);
  });
}

export function registerPolyfills() {
  registerDialog();
}
