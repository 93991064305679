import { $ } from '@local/ts/lib/patchedJQuery';
import 'slick-carousel';

(() => {
    if (typeof $ !== 'function') {
        console.error('jQuery is not loaded');
        return;
    }

    if (typeof $.fn['slick'] !== 'function') {
        console.error('Slick is not loaded into jquery');
        return;
    }

    // configure sliders
    $(window).on('load', function () {
        $('.slideshow').slick({
            arrows: false,
            infinite: true,
            speed: 1000,
            fade: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 12000,
            pauseOnFocus: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            lazyLoad: 'ondemand',
        });
        $('.testimonial-slider').slick({
            arrows: true,
            speed: 1000,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 12000,
            pauseOnFocus: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: false,
            lazyLoad: 'ondemand',
            prevArrow: '<button type="button" class="slick-prev" title="Previous"><i class="fa-solid fa-angle-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next" title="Next"><i class="fa-solid fa-angle-right"></i></button>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }, {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });
})();