// LOCAL SCSS IMPORTS
import "@local/sass/main.scss";

// LOCAL TS IMPORTS
import { $ } from '@local/ts/lib/patchedJQuery';
import { debounce, domReady, throttle, windowReady } from "@local/ts/lib/Utility";
import * as IMask from "@local/ts/interactions/InputMaskInteractions";
import * as DeltaScroll from "@local/ts/interactions/DeltaScrollInteractions";
import * as ElementToggleInteractions from "@local/ts/interactions/ElementToggleInteractions";
import * as GLightboxInteractions from "@local/ts/interactions/GLightboxInteractions";
import { registerPolyfills } from "@local/ts/polyfills/register"; // required for dialog (Needs to be a direct child of body for dialogs to work.)

// LOCAL JS IMPORTS
import '@local/ts/rawJS/slideshows.js';

// INIT CODE
domReady(() => {
    registerPolyfills();
    ElementToggleInteractions.register();
    GLightboxInteractions.register();
    IMask.mask();
    DeltaScroll.register(115, 30);
});

window.jQuery = window.$ = $;

// TYPE DECLARATION
declare global {
    interface Window {
        jQuery: () => void;
        $: () => void;
    }
}