declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GLightbox: CallableFunction;
  }
}

/**
 * registers the GLightbox gallery behavior for the current page
 */
const register = (): void => {
  window.GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
    selector: ".glightbox",
  });
};

export { register };
